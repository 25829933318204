import React from "react";
import Logo from "../../assets/logo/logo.png";

export default function Offcanvas() {
  const menu = [
    {
      name: "Blog",
      link: "#",
    },
    {
      name: "Documentation",
      link: "https://docs.headlockr.io",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ];

  return (
    <div
      className="offcanvas offcanvas-end bg-primary"
      tabIndex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <img
          src={Logo}
          alt="Logo"
          width="175"
          className="d-inline-block align-text-top"
        />
        <button
          type="button"
          className="btn-close text-reset bg-white"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body px-3">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {menu.map((item, index) => (
            <li key={index} className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href={item.link}
                target={item.target || ""}
                rel={item.rel || ""}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
