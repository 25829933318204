import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/logo.png";
import { Link } from "@remix-run/react";
import { motion } from "framer-motion";

// Components
import Nav from "./Nav";
import Offcanvas from "./Offcanvas";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.nav
      initial={{
        background:
          "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
      }}
      animate={{
        background: scroll
          ? "linear-gradient(90deg, rgba(28, 32, 46, 0.9) 0%, rgba(28, 32, 46, 0.9) 100%)"
          : "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
        transition: { duration: 0.7, ease: "easeInOut" },
      }}
      style={{
        zIndex: 1000,
        position: "sticky",
        top: 0,
        width: "100%",
      }}
      className="navbar navbar-dark navbar-expand-lg px-2 py-1"
    >
      <div className="container col-xxl-12">
        <Link className="navbar-brand" to="/">
          <img
            src={Logo}
            alt="Logo"
            width="175"
            className="d-inline-block align-text-top"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Offcanvas />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Nav />
          <div className="d-flex gap-1">
            <Link
              to="mailto:beta@headlockr.io"
              className="btn btn-tertiary"
              type="submit"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </motion.nav>
  );
}
