import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Credits from "./Credits";
import { useLocation } from "@remix-run/react";

export default function Layout({ children }) {
  const location = useLocation();
  return (
    <div className="row">
      <div className="col p-0">
        {location.pathname.includes("checkout") ? null : <Header />}
        {children}
        <Footer />
        <div className="container bg-primary">
          <Credits />
        </div>
      </div>
    </div>
  );
}
